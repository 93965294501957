import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import useStorages from "hooks/useStorages";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles(() => ({
  root: {},
  body: {
    padding: "0 15px 15px 15px",
    position: "relative",
  },
  inputWrapper: {
    marginBottom: 15,
    display: "flex",
    alignItems: "center",
  },
  input: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  select: {
    width: 200,
  },
  title: {
    padding: "15px 15px 5px 15px",
  },
  field: {},
  mr10: {
    marginRight: 10,
  },
  formControl: {},
}));

interface Props {
  open: boolean;
  onClose: Function;
}

interface State {
  name: string;
  storageId: string;
  comment: "";
}

const initialState: State = {
  name: "",
  comment: "",
  storageId: "",
};

const CreateNamespaceDialog: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const { rowData: storages } = useStorages();

  const [state, setState] = useState<State>(initialState);
  const { name, storageId, comment } = state;
  const disabled = name.length === 0 || storageId === "";

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (event: ChangeEvent<{ value: unknown }>) => {
    setState((prev) => ({
      ...prev,
      storageId: event.target.value as string,
    }));
  };

  const handleSave = () => {
    onClose({ ...state, storageId: Number(state.storageId) });
  };

  // обработчик нажатия клавиш
  const handleKeyDown = useCallback(
    (event) => {
      if (!open) return;
      const { key } = event;

      if (key === "Enter" && !disabled) {
        event.preventDefault();
        event.stopPropagation();
        onClose({ ...state, storageId: Number(state.storageId) });
        return;
      }
    },
    [open, onClose, disabled, state]
  );

  useEffect(() => {
    if (storages.length > 0) {
      initialState.storageId = String(storages[0].id);
    }
    setState(initialState);
  }, [open, storages]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      className={classes.root}
      fullWidth
      maxWidth="md"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>Добавить пространство</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Имя"
              fullWidth
              variant="standard"
              name="name"
              onChange={handleChange}
              value={name}
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Комментарий"
              fullWidth
              variant="standard"
              name="comment"
              onChange={handleChange}
              value={comment}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel id="select-diskId-label">Хранилище</InputLabel>
              <Select labelId="select-diskId-label" value={storageId} onChange={handleSelectChange} fullWidth>
                {storages.map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNamespaceDialog;
