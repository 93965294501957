import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Record, RecordDetail, RecordFileViewType } from "types";

// components
import STT from "./STT";
import TextSTT from "./TextTabSTT/TextSTT";
import PlainText from "../PlainText";
import Spinner from "components/Spinner";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles((theme) => ({
  root: {},
  select: {
    width: 200,
  },
  viewTab: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: theme.spacing(2),
    padding: "2px 8px",
    fontSize: theme.typography.fontSize,
    backgroundColor: "#b7e4ff",
    marginLeft: theme.spacing(1),
  },
  popover: {
    padding: theme.spacing(2),
  },
  fg1: {
    flexGrow: 1,
  },
  progress: {
    display: "flex",
    alignItems: "center",
  },
  progressText: {
    marginLeft: 10,
    fontSize: theme.typography.fontSize,
  },
}));

interface Props {
  recordDetail: RecordDetail;
  selectedRecord: Record;
  filterv2: string;
}

const TabStt: FC<Props> = ({ recordDetail, selectedRecord, filterv2 }) => {
  const classes = useStyles();
  const { filter } = recordDetail;

  const [progress, setProgress] = useState(false);

  // audio | video | text | bits | image
  const [viewType, setViewType] = useState<RecordFileViewType>(RecordFileViewType.AUDIO);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleChangeView = (event: ChangeEvent<{ value: unknown }>) => {
    setViewType(event.target.value as RecordFileViewType);
  };

  useEffect(() => {
    setViewType(recordDetail.viewType);
  }, [recordDetail]);

  return (
    <div className={classes.root}>
      <div className={classes.viewTab}>
        <Typography className={classes.title} title={selectedRecord.name}>
          {selectedRecord.name}
        </Typography>

        <div className={classes.fg1}>
          {progress && (
            <div className={classes.progress}>
              <Spinner />
              <span className={classes.progressText}>Запись обрабатывается...</span>
            </div>
          )}
        </div>

        <div>
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon />
          </IconButton>
        </div>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className={classes.popover}>
            <Typography gutterBottom>Тип данных</Typography>
            <Select
              value={viewType}
              onChange={handleChangeView}
              title="Выберете вариант отображения"
              className={classes.select}
            >
              <MenuItem value={RecordFileViewType.AUDIO}>Аудио</MenuItem>
              <MenuItem value={RecordFileViewType.TEXT}>Текст</MenuItem>
              <MenuItem value={RecordFileViewType.BITS}>Бинарный (текст)</MenuItem>
            </Select>
          </div>
        </Popover>
      </div>

      {viewType === RecordFileViewType.AUDIO && (
        <STT
          viewType={viewType}
          queryFilter={filter}
          record={selectedRecord}
          recordDetail={recordDetail}
          filterv2={filterv2}
          setProgress={setProgress}
        />
      )}

      {viewType === RecordFileViewType.TEXT && (
        <TextSTT
          viewType={viewType}
          queryFilter={filter}
          record={selectedRecord}
          recordDetail={recordDetail}
          filterv2={filterv2}
        />
      )}
      {viewType === RecordFileViewType.BITS && <PlainText viewType={viewType} queryFilter={filter} />}
    </div>
  );
};

export default TabStt;
