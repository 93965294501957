import { useEffect, useState, useCallback } from "react";
import { GraphData, GraphNode, GraphEdge, GraphSettings, NodeColor } from "types/graph";
import GraphService from "services/GraphService";
import { generateUuid } from "functions/common";

import PersonIcon from "../components/svg/person.svg";

function getGraphDataFromRes(res: any, mainNodeIds: string) {
  const MAX_HIGHLIGHT_NODES = 5;
  const MAX_NODES_COUNT = 100;
  const BORDER_WIDTH = 4;

  let graphData: GraphData = {
    graphNodes: [],
    graphEdges: [],
  };

  if (
    res.data === null ||
    res.data.tops === null ||
    res.data.edges === null ||
    res.data.tops.length === 0 ||
    res.data.edges.length === 0
  ) {
    return graphData;
  }

  res.data.tops.forEach((node: any) => {
    let nodeColor: NodeColor = {
      border: "#F0544F",
      background: node.color === "" ? "#22b4ff" : node.color,
      highlight: {
        border: "#F0544F",
      },
    };

    let graphNode: GraphNode = {
      id: node.id,
      label: node.name,
      borderWidth:
        new RegExp("(^|,)" + String(node.id) + "(,|$)", "gm").test(mainNodeIds) &&
        mainNodeIds.split(",").length < MAX_HIGHLIGHT_NODES
          ? BORDER_WIDTH
          : 0,
      borderWidthSelected: 4,
      color: nodeColor,
      image: PersonIcon,
      shape: "circularImage",
      font:
        new RegExp("(^|,)" + String(node.id) + "(,|$)", "gm").test(mainNodeIds) &&
        mainNodeIds.split(",").length < MAX_HIGHLIGHT_NODES
          ? "22px arial #343434"
          : "",
    };

    graphData.graphNodes.push(graphNode);
  });

  res.data.edges.forEach((edge: any) => {
    let graphEdge: GraphEdge = {
      id: generateUuid(),
      from: edge.objectId,
      to: edge.parentObjectId,
      title: edge.count === MAX_NODES_COUNT ? "> " + String(edge.count) : String(edge.count),
      label: edge.count === MAX_NODES_COUNT ? "> " + String(edge.count) : String(edge.count),
    };

    graphData.graphEdges.push(graphEdge);
  });

  return graphData;
}

interface State {
  loading: boolean;
  graphData: GraphData;
  error: undefined | Error;
  settings: GraphSettings;
}

const initialState: State = {
  loading: false,
  graphData: {
    graphNodes: [],
    graphEdges: [],
  },
  error: undefined,
  settings: {
    depth: 3,
    backgroud: "#BDBDBD",
  },
};

const useGraph = (ids: string | null) => {
  const [state, setState] = useState<State>(initialState);

  const getGraph = useCallback(async () => {
    if (ids === null) {
      return;
    }
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const resSettings = await GraphService.settings();
      const settings = resSettings.data ?? initialState.settings;
      const res = await GraphService.getAll(ids, String(settings.depth));

      const graphData: GraphData = getGraphDataFromRes(res, ids);
      setState(() => ({
        graphData: graphData,
        loading: false,
        error: undefined,
        settings: resSettings.data ?? initialState.settings,
      }));
    } catch (err) {
      setState(() => ({
        graphData: initialState.graphData,
        loading: false,
        error: err as Error,
        settings: initialState.settings,
      }));
    }
  }, [ids]);

  useEffect(() => {
    getGraph();
  }, [getGraph]);

  return { ...state, getGraph };
};

export default useGraph;
