import React, { FC, useCallback, useRef, useState } from "react";
import moment from "moment";
import { Record } from "types";
import { AdminTypeFilter } from "types/admin";
import { FilterType, Group, Rule } from "types/queryBuilder";
import { RootState } from "redux/types";
import { AgGridReact } from "ag-grid-react";
import { useDispatch, useSelector } from "react-redux";
import { generateUuid } from "functions/common";
import { showErrorAlert } from "redux/actions/alertActions";
import { setPageRecordsTableSettings } from "redux/actions/pageSettingsActions";

// ag-grid
import {
  ColumnResizedEvent,
  GetContextMenuItemsParams,
  MenuItemDef,
  SuppressKeyboardEventParams,
} from "ag-grid-community";
import localization from "components/agGrid/localization";
import { columnDefs as staticColumnDefs } from "./columnDefs";
import { processCellForClipboard } from "components/agGrid/functions";
import RangeInfo from "components/agGrid/RangeInfo";
import DateRenderer from "components/agGrid/renderers/DateRenderer";
import BooleanRenderer from "components/agGrid/renderers/BooleanRenderer";
import LabelsRenderer from "components/agGrid/renderers/LabelsRenderer";
import FloatRenderer from "components/agGrid/renderers/FloatRenderer";
import RecordStatusRenderer from "components/agGrid/renderers/RecordStatusRenderer";
import LangRenderer from "components/agGrid/renderers/LangRenderer";

// material-ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  rangeInfo: {
    position: "fixed",
    zIndex: theme.zIndex.drawer + 15,
    bottom: 0,
    right: 0,
  },
}));

const defaultColDef = {
  resizable: true,
  suppressMenu: true,
};

interface Props {
  rowData: Record[];
  setGridApi: Function;
  setColumnApi: Function;
  onSelection: Function;
  height: string;
  availableFilters: AdminTypeFilter[];
  setFilter: Function;
}

const RecordsTable: FC<Props> = ({
  rowData,
  setGridApi,
  setColumnApi,
  onSelection,
  height,
  availableFilters,
  setFilter,
}) => {
  const classes = useStyles();
  const gridRef = useRef<any>(null);
  const dispatch = useDispatch();
  const { records: pageSettings } = useSelector((state: RootState) => state.pageSettings);
  const [rangeInfo, setRangeInfo] = useState<{ open: boolean; values: number[] }>({ open: false, values: [] });
  const { isShowNameSpaceName, isShowAutoInformatorsName, isShowSpeakersName } = useSelector((state: RootState) => state.access);

  const columnDefs: any = [
    {
      headerName: "Исходящий: Диктор",
      field: "srcSpeakersName",
      valueGetter: (params: any) => {
        const srcSpeakersName = params.data.srcSpeakersName ?? []; 
        const srcSpeakersId = params.data.srcSpeakersId ?? []; 
          return isShowSpeakersName
          ? srcSpeakersName.join(",")
          : srcSpeakersId.join(",")
      },
    },
    {
      headerName: "Входящий: Диктор",
      field: "dstSpeakersName",
      valueGetter: (params: any) => {
        const dstSpeakersName = params.data.dstSpeakersName ?? []; 
        const dstSpeakersId = params.data.dstSpeakersId ?? []; 
          return isShowSpeakersName
          ? dstSpeakersName.join(",")
          : dstSpeakersId.join(",")
    },
    },
    {
      headerName: "Пространство",
      field: isShowNameSpaceName ? "namespaceName" : "namespaceId",
    },
    {
      headerName: "Исходящий: Автоинформатор",
      field: isShowAutoInformatorsName ? "srcAutoinformatorName" : "srcAutoinformatorId",
    },
    {
      headerName: "Входящий: Автоинформатор",
      field: isShowAutoInformatorsName ? "dstAutoinformatorName" : "dstAutoinformatorId",
    },
    ...staticColumnDefs,
  ];
  

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    if (pageSettings.table !== undefined) {
      params.columnApi.setColumnState(pageSettings.table);
    }
  };

  const onSelectionChanged = () => {
    onSelection();
  };

  const getRowStyle = (params: any) => {
    const isViewed = params.node.data?.isViewed ?? false;
    if (isViewed) {
      return { backgroundColor: "#e0e0e0" };
    }
  };

  const getRowNodeId = (data: Record) => String(data.id);

  const onColumnResized = useCallback(
    (params: ColumnResizedEvent) => {
      const { finished, columnApi, source } = params;
      if (source === "flex" || source === "api") return;
      if (finished) {
        const colState = columnApi.getColumnState();
        dispatch(setPageRecordsTableSettings(colState));
      }
    },
    [dispatch]
  );

  const contextFilter = useCallback(
    (params: GetContextMenuItemsParams, op: "=" | "!=") => {
      const { column, node, value } = params;

      // название поля латиницей
      const colName = column.getColId();

      const availableFilter = availableFilters.find((f) => f.jsonName === colName);

      // если поля нет в доступных фильтрах
      if (availableFilter === undefined) {
        dispatch(showErrorAlert("Фильтрация по полю не поддерживается."));
        return;
      }
      // если значение поля не удалось определить
      if (value === undefined) {
        dispatch(showErrorAlert("Не удалось определить значение ячейки."));
        return;
      }

      // правило для добавления
      const rule: Rule = {
        type: "filter",
        uuid: generateUuid(),
        filter: availableFilter.name,
        condition: op,
        value: String(value),
      };

      // При пкм смотреть тип Фильтра и если это number или float, то ставить знак ">", в остальных случаях "="
      if (availableFilter.type === FilterType.Number || availableFilter.type === FilterType.Float) {
        rule.condition = ">";
      }

      // Если поле = Дата сеанса
      if (colName === "dateRecord") {
        try {
          const cellDate = moment(value).set("hour", 0).set("minutes", 0).set("seconds", 0).set("milliseconds", 0);
          const nextDay = moment(value)
            .add(1, "day")
            .set("hour", 0)
            .set("minutes", 0)
            .set("seconds", 0)
            .set("milliseconds", 0);
          rule.value = `${cellDate.toISOString(true)}\n${nextDay.toISOString(true)}`;
        } catch (e) {}
      }

      // Если тип значение логический
      if (typeof value === "boolean") {
        rule.value = value ? "да" : "нет";
      }

      // cellDbValue - занчение которое лежит в базе
      const cellDbValue = node.data[colName];

      // если отображаемое значение равно пустоте
      if (value === "") {
        rule.value = String(cellDbValue);
      }

      // поля исключения для которых надо добавлять то что в базе
      const exception = ["realDurationRecord", "srcDurationSpeech", "dstDurationSpeech", "durationRecord"];
      if (exception.includes(colName)) {
        rule.value = String(cellDbValue);
      }

      setFilter((prev: Group) => ({ ...prev, filters: [...prev.filters, rule] }));
    },
    [availableFilters, setFilter, dispatch]
  );

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => [
      {
        name: "Фильтровать по значению",
        // icon: '<img alt="filter" width="15" height="15" src="/img/filter.png"/>',
        action: () => {
          contextFilter(params, "=");
        },
      },
      {
        name: "Исключить из выборки",
        action: () => {
          contextFilter(params, "!=");
        },
      },
      "separator",
      "copy",
    ],
    [contextFilter]
  );

  const suppressKeyboardEvent = (event: SuppressKeyboardEventParams) => {
    const { key } = event.event;
    return key === "ArrowLeft" || key === "ArrowRight";
  };

  const onRangeSelectionChanged = (event: any) => {
    try {
      const { api, finished } = event;
      if (finished) {
        const cellRangesArr = api.getCellRanges();
        if (cellRangesArr !== null) {
          const [cellRanges] = cellRangesArr;
          const colId = cellRanges?.startColumn?.colId ?? "";

          const startRowIndex = cellRanges?.startRow?.rowIndex ?? 0;
          const endRowIndex = cellRanges?.endRow?.rowIndex ?? 0;
          let start = startRowIndex;
          let end = endRowIndex;
          // если выделяли снизу вверх, startRowIndex будет больше endRowIndex
          if (startRowIndex > endRowIndex) {
            start = endRowIndex;
            end = startRowIndex;
          }
          const values = [];
          for (let i = start; i <= end; i++) {
            const node = api.getDisplayedRowAtIndex(i);
            const value = node.data[colId];
            if (typeof value === "number") {
              values.push(value);
            }
          }
          setRangeInfo({ open: true, values });
        }
      }
    } catch (err) {}
  };

  return (
    <div className="ag-theme-balham" style={{ height }}>
      <AgGridReact
        suppressKeyboardEvent={suppressKeyboardEvent}
        ref={gridRef}
        animateRows={false}
        suppressMovableColumns
        suppressColumnMoveAnimation
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        getRowNodeId={getRowNodeId}
        getRowStyle={getRowStyle}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="multiple"
        onSelectionChanged={onSelectionChanged}
        enableRangeSelection
        suppressCopyRowsToClipboard
        onColumnResized={onColumnResized}
        suppressDragLeaveHidesColumns
        getContextMenuItems={getContextMenuItems}
        onRangeSelectionChanged={onRangeSelectionChanged}
        suppressMultiRangeSelection
        processCellForClipboard={processCellForClipboard}
        frameworkComponents={{
          DateRenderer,
          BooleanRenderer,
          LabelsRenderer,
          FloatRenderer,
          RecordStatusRenderer,
          LangRenderer,
        }}
      />
      {rangeInfo.open && rangeInfo.values.length > 1 && (
        <div className={classes.rangeInfo}>
          <RangeInfo values={rangeInfo.values} />
        </div>
      )}
    </div>
  );
};

export default RecordsTable;
