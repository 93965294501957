import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { Condition as TCondition, FilterType, Group as TGroup, Rule as TRule } from "types/queryBuilder";
import { AdminTypeFilter, NumberParams } from "types/admin";

// components
import RenderInput from "./values/RenderInput";
import Condition from "./Condition";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { grey } from "@material-ui/core/colors";
import HintRender from "components/searchFilter/components/HintRender";

const useStyles = makeStyles(() => ({
  root: {
    height: 465,
  },
  operation: {
    marginBottom: 20,
  },
  field: {
    marginBottom: 20,
  },
  condition: {
    marginBottom: 20,
  },
  value: {
    height: 300,
  },
  hintIcon: {
    color: grey[600],
    marginRight: "1rem",
    zIndex: 100,
    cursor: "pointer",
  },
}));

interface Props {
  rule: TRule;
  parentGroup: TGroup;
  changeRule: (r: TRule, p: TGroup) => void;
  availableFilters: AdminTypeFilter[];
}

const DetailRule: FC<Props> = ({ rule: r, parentGroup, changeRule, availableFilters }) => {
  const classes = useStyles();

  const [rule, setRule] = useState(r);
  const { condition, value, filter } = rule;

  const baseFilter = availableFilters.find((p) => p.name === filter);

  const ruleType = baseFilter ? baseFilter.type : "";

  const handleFilterChange = (event: any, filter: AdminTypeFilter | null) => {
    if (filter === null) return;
    let condition: TCondition = "=";
    const r: TRule = { ...rule, filter: filter.name, condition, value: "" };

    if (filter.type === FilterType.Number || filter.type === FilterType.Float) {
      const availableFilter = availableFilters.find((f) => f.name === filter.name);
      if (availableFilter && availableFilter.params) {
        const params = availableFilter.params as NumberParams;
        r.value = params.default;
      }
    }

    if (filter.type === FilterType.Float) {
      r.condition = ">";
    }

    changeRule(r, parentGroup);
    setRule(r);
  };

  const handleConditionChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const { value } = event.target;
      const r = { ...rule, condition: value as TCondition };
      changeRule(r, parentGroup);
      setRule(r);
    },
    [parentGroup, rule, changeRule]
  );

  const changeTextInput = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = event.target;
      const r = { ...rule, value: value };
      changeRule(r, parentGroup);
      setRule(r);
    },
    [parentGroup, rule, changeRule]
  );

  const changeBooleanInput = useCallback(
    (value: string) => {
      const r: TRule = { ...rule, value };
      changeRule(r, parentGroup);
      setRule(r);
    },
    [parentGroup, rule, changeRule]
  );

  const changeNumberInput = useCallback(
    (value: number | string) => {
      const r: TRule = { ...rule, value: String(value) };
      changeRule(r, parentGroup);
      setRule(r);
    },
    [parentGroup, rule, changeRule]
  );

  const changeStringInput = useCallback(
    (value: string) => {
      const r: TRule = { ...rule, value: value };
      changeRule(r, parentGroup);
      setRule(r);
    },
    [parentGroup, rule, changeRule]
  );

  useEffect(() => {
    setRule(r);
  }, [r]);

  return (
    <div className={classes.root}>
      <div className={classes.field}>
        <Typography gutterBottom>Категория:</Typography>
        <Autocomplete
          fullWidth
          value={baseFilter}
          onChange={handleFilterChange}
          onKeyDown={(e) => e.stopPropagation()}
          options={availableFilters}
          getOptionLabel={(f: AdminTypeFilter) => f.name}
          renderInput={(params) => <TextField {...params} fullWidth />}
          renderOption={(option) => <HintRender option={option.name} />}
        />
      </div>

      <div className={classes.condition}>
        <Typography gutterBottom>Операция:</Typography>
        {baseFilter && <Condition condition={condition} handleChange={handleConditionChange} filterType={ruleType} />}
      </div>

      <div className={classes.value}>
        <Typography gutterBottom>Значение:</Typography>
        <RenderInput
          ruleType={ruleType}
          value={value}
          changeTextInput={changeTextInput}
          changeBooleanInput={changeBooleanInput}
          changeNumberInput={changeNumberInput}
          changeStringInput={changeStringInput}
          availableFilters={availableFilters}
          filterName={filter}
        />
      </div>
    </div>
  );
};

export default DetailRule;
