import React, { FC, useCallback } from "react";
import { RootState } from "redux/types";
import { setPageWordsTableSettings } from "redux/actions/pageSettingsActions";
import { useDispatch, useSelector } from "react-redux";

// ag-grid
import { AgGridReact } from "ag-grid-react";
import { ColumnResizedEvent } from "ag-grid-community";
import localization from "components/agGrid/localization";
import DateRenderer from "components/agGrid/renderers/DateRenderer";
import ColorRenderer from "components/agGrid/renderers/ColorRenderer";
import { langGetter } from "components/agGrid/valueGetters";

const defaultColDef = {
  resizable: true,
  sortable: true,
};

const columnDefs = [
  {
    headerName: "Язык",
    field: "lang",
    valueGetter: langGetter,
  },
  {
    headerName: "Цвет",
    field: "color",
    cellRenderer: "ColorRenderer",
  },
  {
    headerName: "Комментарий",
    field: "comment",
    flex: 1,
  },
  {
    headerName: "Дата изменения",
    field: "dateChanged",
    cellRenderer: "DateRenderer",
  },
];

const autoGroupColumnDef = {
  headerName: "Группа",
  field: "name",
  minWidth: 300,
  cellRendererParams: {
    suppressCount: true,
    checkbox: true,
  },
};

interface Props {
  rowData: any[];
  setGridApi: Function;
  setColumnApi: Function;
  onSelection: Function;
  height: string | number;
}

const WordsTable: FC<Props> = ({ rowData, setGridApi, setColumnApi, onSelection, height }) => {
  const dispatch = useDispatch();
  const { words: pageSettings } = useSelector((state: RootState) => state.pageSettings);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    if (pageSettings.table !== undefined) {
      params.columnApi.setColumnState(pageSettings.table);
    }
  };

  const onSelectionChanged = () => {
    onSelection();
  };

  const getRowNodeId = (data: any) => String(data.id);

  const getDataPath = useCallback((data) => data.group.split("/"), []);

  const onColumnResized = useCallback(
    (params: ColumnResizedEvent) => {
      const { finished, columnApi, source } = params;
      if (source === "flex" || source === "api") return;
      if (finished) {
        const colState = columnApi.getColumnState();
        dispatch(setPageWordsTableSettings(colState));
      }
    },
    [dispatch]
  );

  return (
    <div className={"ag-theme-balham"} style={{ height }}>
      <AgGridReact
        onGridReady={onGridReady}
        autoGroupColumnDef={autoGroupColumnDef}
        defaultColDef={defaultColDef}
        getRowNodeId={getRowNodeId}
        rowData={rowData}
        columnDefs={columnDefs}
        localeText={localization}
        rowSelection="multiple"
        enableRangeSelection
        onSelectionChanged={onSelectionChanged}
        suppressDragLeaveHidesColumns
        onColumnResized={onColumnResized}
        suppressMovableColumns
        suppressColumnMoveAnimation
        suppressCopyRowsToClipboard
        frameworkComponents={{
          DateRenderer,
          ColorRenderer,
        }}
        treeData
        getDataPath={getDataPath}
      />
    </div>
  );
};

export default WordsTable;
