import React, { ChangeEvent, FC } from "react";
import { FolderMag as T } from "types/source";
import PasswordInput from "components/form/PasswordInput";

// material ui
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import PathMag from "./PathMag";

interface Props {
  state: T;
  setPathValue: (value: string, pathName?: string) => void;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const FolderMag: FC<Props> = ({ state, onChange, setPathValue }) => {
  const { host, login, password } = state;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField label="Хост" fullWidth variant="standard" name="host" onChange={onChange} value={host} />
      </Grid>

      <Grid item xs={12}>
        <TextField label="Логин" fullWidth variant="standard" name="login" onChange={onChange} value={login} />
      </Grid>

      <Grid item xs={12}>
        <PasswordInput handleChange={onChange} value={password} margin="none" />
      </Grid>
      <Grid item xs={12}>
          <PathMag state={state} setPathValue={setPathValue} onChange={onChange} />
      </Grid>
        
    </Grid>
  );
};

export default FolderMag;
