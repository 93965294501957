import React, { useState, FC, ChangeEvent, useEffect, useCallback, useMemo } from "react";
import { Column, ColumnApi, GridApi } from "ag-grid-community";
import { useDispatch } from "react-redux";
import { Namespace } from "types/namespace";
import { Permission } from "types/permission";
import { FileFormatDetail } from "types/admin";
import { Group, Rule } from "types/queryBuilder";
import { showErrorAlert, showSuccessAlert } from "redux/actions/alertActions";
import NamespaceService, { BodySpace } from "services/NamespaceService";
import useNamespaces from "hooks/namespace/useNamespaces";
import { addRow, removeRows, updateColumnOrder, updateRow } from "components/agGrid/functions";
import { FileUpload } from "services/FileUploadService";
import { setPageNamespacesTableSettings } from "redux/actions/pageSettingsActions";

// components
import SpacesTable from "./components/NamespaceTable";
import CreateNamespaceDialog from "./components/CreateNamespaceDialog";
import UpdateNamespaceDialog from "./components/UpdateNamespaceDialog";
import UploadFilesDialog from "./components/UploadFilesDialog";
import ConfirmationDialog from "components/ConfirmationDialog";
import SetNamespacePermissions from "./components/SetNamespacePermissions";
import TableSettingsDialog, { TableCol } from "components/agGrid/TableSettingsDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import GridOnIcon from "@material-ui/icons/GridOn";
import CreateTaskDialog from "../tasks/components/CreateTaskDialog";
import CreateExportTaskDialog from "../tasks/components/CreateExportTaskDialog";
import { TaskDetailType } from "../../types";
import TaskService from "../../services/TaskService";
import { generateUuid } from "../../functions/common";
import useAvailableFilters from "../../hooks/admin/useAvailableFilters";

const useStyles = makeStyles((theme) => ({
  root: {},
  empty: {
    color: theme.palette.text.disabled,
    textAlign: "center",
  },
  progress: {
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    marginLeft: 10,
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchInput: {
    fontSize: 14,
  },
  pagination: {
    paddingTop: 5,
  },
  searchWrapper: {
    flexGrow: 1,
    marginLeft: 10,
  },
}));

const TABLE_SCROLL_TOP = "APP_NAMESPACE_TABLE_SCROLL_TOP";

const Namespaces: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );

  const { namespaces, error } = useNamespaces();
  const { filters: availableFilters } = useAvailableFilters("records");

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openUploadFilesDialog, setOpenUploadFilesDialog] = useState(false);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);
  const [openSetPermissionsDialog, setOpenSetPermissionsDialog] = useState(false);
  const [openSettingsTableDialog, setOpenSettingsTableDialog] = useState(false);

  const [openCreateTaskDialog, setOpenCreateTaskDialog] = useState(false);
  const [openCreateExportTaskDialog, setOpenCreateExportTaskDialog] = useState(false);
  const [createTaskFilter, setCreateTaskFilter] = useState(defaultFilter);

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [columnApi, setColumnApi] = useState<ColumnApi | undefined>(undefined);
  const [tableCols, setTableCols] = useState<{ displayedColumns: Column[]; allGridColumns: Column[] }>({
    displayedColumns: [],
    allGridColumns: [],
  });

  const [selectedRows, setSelectedRows] = useState<Namespace[]>([]);

  const handleCloseSettingsDialog = (data?: TableCol[]) => {
    setOpenSettingsTableDialog(false);
    if (data && columnApi) {
      updateColumnOrder(data, columnApi);
      const colState = columnApi.getColumnState();
      dispatch(setPageNamespacesTableSettings(colState));
    }
  };

  const onSelection = useCallback(() => {
    if (gridApi === undefined) return;

    const rows = gridApi.getSelectedRows();
    setSelectedRows(rows);

    const ids = rows.map((el) => el.id).join("\n");

    const filter = availableFilters.find((f) => f.jsonName === "namespaceId");
    if (filter === undefined) return;

    const rule: Rule = {
      condition: "=",
      filter: filter.name,
      type: "filter",
      uuid: generateUuid(),
      value: ids,
    };
    setCreateTaskFilter((prev) => ({ ...prev, filters: [rule] }));
  }, [gridApi, availableFilters]);

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  };

  // создание
  const handleCloseCreateDialog = (data?: BodySpace) => {
    setOpenCreateDialog(false);
    if (data !== undefined) {
      NamespaceService.create(data)
        .then((res) => {
          addRow(res.data, gridApi);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  // редактирование
  const handleCloseUpdateDialog = (dict: BodySpace) => {
    setOpenUpdateDialog(false);
    if (dict !== undefined) {
      NamespaceService.update(dict)
        .then(() => {
          updateRow(dict, gridApi);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  // изменение прав
  const handleCloseChangePermissionsDialog = (data?: Permission[]) => {
    setOpenSetPermissionsDialog(false);
    if (data !== undefined && selectedRows.length === 1) {
      const { id } = selectedRows[0];
      NamespaceService.setNamespacePermissions(id, data)
        .then(() => {
          dispatch(showSuccessAlert("Права изменены"));
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  // загрузка файлов
  const handleCloseUploadFilesDialog = (data?: {
    fileFormat: FileFormatDetail;
    files: File[];
    deleteByFilter: Group;
  }) => {
    setOpenUploadFilesDialog(false);
    if (data) {
      try {
        const { fileFormat, files, deleteByFilter } = data;
        const formData = new FormData();
        formData.append("fileFormatDetail", JSON.stringify(fileFormat));
        formData.append("deleteByFilter", JSON.stringify(deleteByFilter));
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
        }
        const namespace = selectedRows[0];
        const onSuccess = () => {
          dispatch(showSuccessAlert("Файлы загружены успешно."));
        };
        const onError = (err: any) => {
          catchError(err?.response?.data ?? err);
        };
        FileUpload(namespace, formData, onSuccess, onError);
      } catch (e) {}
    }
  };

  // удаление
  const onCloseDeleteDialog = (confirm: boolean) => {
    setOpenDeleteConfirmationDialog(false);
    if (confirm) {
      const ids = selectedRows.map((el) => el.id).join(",");
      NamespaceService.remove(ids)
        .then(() => {
          removeRows(selectedRows, gridApi);
          setSelectedRows([]);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  const handleDeleteSpace = () => {
    setOpenDeleteConfirmationDialog(true);
  };

  const isDisabledRemove = useCallback(() => {
    if (selectedRows.length === 0) return true;
    return !selectedRows.every((n) => n.isCanWrite);
  }, [selectedRows]);

  const isDisabledUpdate = useCallback(() => {
    if (selectedRows.length !== 1) return true;
    return !selectedRows[0].isCanWrite;
  }, [selectedRows]);

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  const onFirstDataRendered = () => {
    const element = document.querySelector(".ag-body-viewport");
    const scrollTop = sessionStorage.getItem(TABLE_SCROLL_TOP);
    if (element && scrollTop) {
      element.scrollTop = Number(scrollTop);
    }
  };

  const handleCloseCreateTaskDialog = (data?: any) => {
    setOpenCreateTaskDialog(false);
    if (data !== undefined) {
      const { name, comment, isActive, isForce, techs, filter } = data;
      const body: any = {
        name,
        comment,
        isActive,
        taskDetail: {
          type: TaskDetailType.Process,
          filterDetail: filter,
          techDetail: {
            isForce,
            techs,
          },
          exports: data.exports,
        },
      };
      TaskService.create(body)
        .then(() => {
          dispatch(showSuccessAlert("Задание создано и поставлено в очередь обработки."));
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  const handleCloseCreateExportTaskDialog = (data?: any) => {
    setOpenCreateExportTaskDialog(false);
    if (data !== undefined) {
      TaskService.create(data)
        .then(() => {
          dispatch(showSuccessAlert("Задание на экспорт создано."));
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  useEffect(() => {
    if (error) {
      catchError(error);
    }
  }, [error, catchError]);

  useEffect(() => {
    return () => {
      if (gridApi) {
        const { top } = gridApi.getVerticalPixelRange();
        sessionStorage.setItem(TABLE_SCROLL_TOP, String(top));
      }
    };
  }, [gridApi]);

  useEffect(() => {
    if (columnApi === undefined) return;
    if (openSettingsTableDialog) {
      try {
        const displayedColumns: Column[] = columnApi.getAllDisplayedColumns();
        const allGridColumns: Column[] = columnApi.getAllGridColumns();
        setTableCols({ displayedColumns, allGridColumns });
      } catch (e) {}
    }
  }, [columnApi, openSettingsTableDialog]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div className={classes.actions}>
              <div className={classes.buttons}>
                <IconButton
                  onClick={() => setOpenSettingsTableDialog(true)}
                  title="Настройка колонок таблицы"
                  size="small"
                >
                  <GridOnIcon fontSize="inherit" />
                </IconButton>
                <div className={classes.searchWrapper}>
                  <TextField
                    className={classes.search}
                    placeholder="Имя пространства"
                    onChange={onQuickFilterChanged}
                    InputProps={{
                      className: classes.searchInput,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={selectedRows.length === 0}
                    onClick={() => setOpenCreateTaskDialog(true)}
                  >
                    Создать задание
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={selectedRows.length === 0}
                    onClick={() => setOpenCreateExportTaskDialog(true)}
                  >
                    Экспорт
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={isDisabledUpdate()}
                    onClick={() => setOpenSetPermissionsDialog(true)}
                  >
                    Права
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={isDisabledRemove()}
                    onClick={handleDeleteSpace}
                  >
                    Удалить
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={isDisabledUpdate()}
                    onClick={() => setOpenUpdateDialog(true)}
                  >
                    Редактировать
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.button}
                    disabled={isDisabledUpdate()}
                    onClick={() => setOpenUploadFilesDialog(true)}
                  >
                    Загрузить
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    className={classes.button}
                    onClick={() => setOpenCreateDialog(true)}
                  >
                    Создать
                  </Button>
                </div>
              </div>
            </div>

            <SpacesTable
              rowData={namespaces}
              setGridApi={setGridApi}
              setColumnApi={setColumnApi}
              onSelection={onSelection}
              onFirstDataRendered={onFirstDataRendered}
            />
          </Paper>
        </Grid>
      </Grid>
      <TableSettingsDialog open={openSettingsTableDialog} onClose={handleCloseSettingsDialog} cols={tableCols} />
      <ConfirmationDialog open={openDeleteConfirmationDialog} onClose={onCloseDeleteDialog} />
      <CreateNamespaceDialog open={openCreateDialog} onClose={handleCloseCreateDialog} />
      {selectedRows.length === 1 && (
        <>
          <UpdateNamespaceDialog open={openUpdateDialog} onClose={handleCloseUpdateDialog} space={selectedRows[0]} />
          <UploadFilesDialog open={openUploadFilesDialog} onClose={handleCloseUploadFilesDialog} />
          <SetNamespacePermissions
            open={openSetPermissionsDialog}
            onClose={handleCloseChangePermissionsDialog}
            namespaceId={selectedRows[0].id}
          />
        </>
      )}
      <CreateTaskDialog
        open={openCreateTaskDialog}
        onClose={handleCloseCreateTaskDialog}
        filterDetail={createTaskFilter}
        isForce={false}
      />
      <CreateExportTaskDialog
        open={openCreateExportTaskDialog}
        onClose={handleCloseCreateExportTaskDialog}
        filterDetail={createTaskFilter}
      />
    </div>
  );
};

export default Namespaces;
