import React, { ChangeEvent, FC, useState, useEffect } from "react";
import { FolderMag, FolderSftp, FolderType } from "types/source";
import { ObzorTypeSFTP } from "types/obzor";
import StorageService from "services/StorageService";
import { catchError } from "functions/common";
import ObzorSFTP from "components/obzor/ObzorSFTP";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(() => ({
  button: {
    width: 82,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "end",
    gap: "20px",
  },
}));

interface Props {
  state: FolderMag;
  setPathValue: (value: string, pathName?: string) => void;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const PathMag: FC<Props> = ({ state, onChange, setPathValue }) => {
  const classes = useStyles();
  const { host, login, password, path } = state;
  const disabled = host === "" || login === "" || password === "";

  const [openObzor, setOpenObzor] = useState(false);
  const [files, setFiles] = useState<ObzorTypeSFTP[]>([]);
  const [breadcrumbPath, setBreadcrumbPath] = useState("");
  const [filterValue, setFilterValue] = useState<string>("");

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFilter = event.target.value;
    setFilterValue(newFilter);
  };

  const handleFolderClick = (folderName: string) => {
    // Нажатие на имя папки
    setBreadcrumbPath(folderName);
    const obzor: FolderSftp = {
      type: FolderType.SFTP,
      path: folderName,
      host,
      login,
      password,
    };

    StorageService.obzor(obzor)
      .then(({ data }) => {
        setFiles(data);
        setFilterValue("");
      })
      .catch(catchError);
  };

  const handleSelectClick = (type?: string, name?: string) => {
    setOpenObzor(false);
    if (name) {
      setPathValue(name);
    }
  };

  const handleCrumbClick = (newPath: string) => {
    setBreadcrumbPath(newPath);

    const obzor: FolderSftp = {
      type: FolderType.SFTP,
      path: newPath,
      host,
      login,
      password,
    };

    StorageService.obzor(obzor)
      .then(({ data }) => {
        setFiles(data);
        setFilterValue("");
      })
      .catch(catchError);
  };

  const handleMainCrumbClickSFTP = () => {
    setBreadcrumbPath("");
    setPathValue("/");
    const obzor: FolderSftp = {
      type: FolderType.SFTP,
      path: "/",
      host,
      login,
      password,
    };

    StorageService.obzor(obzor)
      .then(({ data }) => {
        setFiles(data);
        setFilterValue("");
      })
      .catch(catchError);
  };

  const handleOpenObzor = () => {
    setBreadcrumbPath(path);
    setOpenObzor(true);
    const obzor: FolderSftp = {
      type: FolderType.SFTP,
      path: path,
      host,
      login,
      password,
    };

    StorageService.obzor(obzor)
      .then(({ data }) => {
        setFiles(data);
        setPathValue(path);
      })
      .catch(catchError);
  };

  useEffect(() => {
    setFilterValue("");
  }, [openObzor]);

  useEffect(() => {
    setBreadcrumbPath(path);
  }, [path]);

  return (
    <div className={classes.wrapper}>
      <TextField label="Путь" fullWidth variant="standard" name="path" onChange={onChange} value={path} />
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        size="small"
        onClick={handleOpenObzor}
        disabled={disabled}
      >
        Обзор
      </Button>
      <ObzorSFTP
        filterValue={filterValue}
        handleFilterChange={handleFilterChange}
        open={openObzor}
        onClose={() => setOpenObzor(false)}
        files={files}
        folderClick={handleFolderClick}
        handleSelect={handleSelectClick}
        handleCrumbClick={handleCrumbClick}
        handleMainCrumbClickSFTP={handleMainCrumbClickSFTP}
        breadcrumbPath={breadcrumbPath}
      />
    </div>
  );
};

export default PathMag;