import React, { ChangeEvent, FC, useEffect, useState } from "react";
import clsx from "clsx";
import { TaskTechTranslate } from "types/task";
import { checkError, getCyrillicLangName } from "functions/common";
import useTranslateLanguages from "hooks/useTranslateLanguages";

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(() => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 100,
  },
  mr10: {
    marginRight: 10,
  },
}));

interface Props {
  open: boolean;
  onClose: Function;
  tech: TaskTechTranslate;
}

const Translate: FC<Props> = ({ open, onClose, tech }) => {
  const classes = useStyles();

  const { languagesTo, error } = useTranslateLanguages();
  const [state, setState] = useState(tech);

  const handleSave = () => {
    onClose(state);
  };

  const handleChangeLang = (event: ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    setState((prev: any) => ({
      ...prev,
      lang: value,
    }));
  };

  useEffect(() => {
    checkError(error);
  }, [error]);

  useEffect(() => {
    setState({ ...tech, lang: "russian" });
  }, [tech]);

  return (
    <Dialog onClose={() => onClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle>Настройка</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {languagesTo.length === 0 && (
            <Grid item xs={12}>
              <Typography>Нет доступных языков для перевода</Typography>
            </Grid>
          )}

          {languagesTo.length > 0 && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="select-lang">Язык</InputLabel>
                <Select labelId="select-lang" name="lang" value={state.lang} onChange={handleChangeLang} fullWidth>
                  {languagesTo.map((lang, idx) => (
                    <MenuItem key={idx} value={lang}>
                      {getCyrillicLangName(lang)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <Button className={clsx(classes.button, classes.mr10)} size="small" onClick={() => onClose()}>
            Отмена
          </Button>
          <Button className={classes.button} variant="contained" size="small" color="primary" onClick={handleSave}>
            Ок
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default Translate;
