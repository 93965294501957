import { AxiosResponse } from "axios";
import { records } from "http/urls";
import api from "http/api";

function ReplicaEditorService() {
  function getAll(id: number): Promise<AxiosResponse<any>> {
    const url = records.editorData.replace(":id", String(id));
    return api.get<any>(url);
  }

  function setText(id: number, body: {text: string}): Promise<AxiosResponse> {
    const url = records.editorData.replace(":id", String(id));
    return api.post(url, body);
  }

  function getFile(body: {text: string, format: string}): Promise<AxiosResponse> {
    const url = records.textconverter;
    return api.post(url, body);
  }
  return Object.freeze({
    getAll,
    setText,
    getFile,
  });
}

export default ReplicaEditorService()