import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import useQueryString from "hooks/useQueryString";
import useGraph from "hooks/useGraph";
import { useDispatch } from "react-redux";
import { showErrorAlert } from "redux/actions/alertActions";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import GraphService from "services/GraphService";
import { generateUuid } from "functions/common";

// components
import GraphView from "./components/GraphView";
import NoData from "../../components/NoData";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    position: "relative",
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  barPaper: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  noData: {
    height: "820px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  progress: {
    position: "absolute",
    top: "70px",
    left: "10px",
    height: "820px",
    width: "calc(100% - 16px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    margin: "0 5px",
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchInput: {
    fontSize: 14,
  },
  pagination: {
    paddingTop: 5,
  },
  opacityZero: {
    opacity: 0,
  },
  opacityFull: {
    opacity: 100,
    position: "absolute",
  },
  loadingSign: {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: "14px!important",
    textAlign: "center",
  },
  nodesCount: {
    position: "absolute",
    top: "14px",
    right: "20px",
    fontSize: 14,
    zIndex: 5,
  },
}));

const Graph: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryString();

  const ids = queryParams.get("ids");

  const { graphData, loading, error } = useGraph(ids);
  const [network, setNetwork] = useState<any>(null);
  const [graphLoading, setGraphLoading] = useState<boolean>(false);
  const [graphLoaded, setGraphLoaded] = useState<boolean>(false);

  const [selectedNodes, setSelectedNodes] = useState<any[]>([]);
  const [selectedEdges, setSelectedEdges] = useState<any[]>([]);

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (network === null) {
      return;
    }

    if (value === "") {
      network.unselectAll();
      return;
    }

    let nodesIdsToSelect: number[] = [];
    graphData.graphNodes.forEach((node) => {
      if (node.label.includes(value)) {
        nodesIdsToSelect.push(node.id);
      }
    });

    if (nodesIdsToSelect.length !== 0) {
      network.selectNodes(nodesIdsToSelect, true);
      return;
    }

    if (nodesIdsToSelect.length === 0) {
      network.unselectAll();
      return;
    }
  };

  const handleOpenResult = () => {
    if (selectedNodes.length > 0) {
      const ids = selectedNodes.map((n) => n.id).join(",");
      const { path } = routes.results;
      const url = path + "?filter=" + JSON.stringify([["Объект", "=", [ids]]]);
      history.push(encodeURI(url));
      return;
    }
    if (selectedEdges.length > 0) {
      const { from, to } = selectedEdges[0];
      const { path } = routes.results;
      const object = String(from) + "," + String(to);
      const url = path + "?filter=" + JSON.stringify([["Объект", "=", [object]]]);
      history.push(encodeURI(url));
      return;
    }
  };

  const handleAddEdge = () => {
    if (selectedNodes.length !== 2) {
      return;
    }

    GraphService.createLink(String(selectedNodes[0].id), String(selectedNodes[1].id))
      .then(() => {
        network.body.data.edges.add({ id: generateUuid(), from: selectedNodes[0].id, to: selectedNodes[1].id });
        network.unselectAll();
        setSelectedNodes([]);
        setSelectedEdges([]);
      })
      .catch((err) => catchError(err.response.data));
  };

  const handleDeleteEdge = () => {
    if (selectedEdges.length !== 1) {
      return;
    }

    const edgeToDelete = network.body.data.edges.get(selectedEdges[0].id);
    GraphService.removeLink(String(edgeToDelete?.from), String(edgeToDelete?.to))
      .then(() => {
        network.deleteSelected();
        network.unselectAll();
        setSelectedNodes([]);
        setSelectedEdges([]);
      })
      .catch((err) => catchError(err.response.data));
  };

  const graphEvents = {
    select: function (event: any) {
      const { nodes: nodesIds, edges: edgesIds } = event;
      if (nodesIds.length === 0 && edgesIds.length === 0) {
        setSelectedNodes([]);
        setSelectedEdges([]);
      }

      const nodes = network.body.data.nodes.get(nodesIds);
      const edges = network.body.data.edges.get(edgesIds);
      setSelectedNodes(nodes);
      setSelectedEdges(edges);
    },
    startStabilizing: function (event: any) {
      if (graphLoaded) {
        return;
      }
      setGraphLoading(true);
    },
    stabilized: function (event: any) {
      if (graphLoaded) {
        return;
      }
      setGraphLoading(false);
      setGraphLoaded(true);
    },
  };

  const isOpenResult = () => {
    if (selectedNodes.length > 0 && selectedNodes.length < 3) {
      return true;
    }

    return selectedNodes.length === 0 && selectedEdges.length === 1;
  };

  const isDeleteEdge = () => selectedNodes.length === 0 && selectedEdges.length === 1;

  useEffect(() => {
    if (error) {
      catchError(error);
    }
  }, [catchError, error]);

  return (
    <div className={classes.root}>
      <Paper className={classes.barPaper}>
        <div className={classes.actions}>
          <div className={classes.buttons}>
            <div>
              <TextField
                className={classes.search}
                placeholder="Поиск..."
                onChange={onQuickFilterChanged}
                InputProps={{
                  className: classes.searchInput,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div>
              <Button
                color="primary"
                size="small"
                className={classes.button}
                onClick={handleDeleteEdge}
                disabled={!isDeleteEdge()}
              >
                Удалить связь
              </Button>
              <Button
                color="primary"
                size="small"
                className={classes.button}
                onClick={handleAddEdge}
                disabled={selectedNodes.length !== 2}
              >
                Добавить связь
              </Button>
              <Button
                color="primary"
                size="small"
                className={classes.button}
                onClick={handleOpenResult}
                disabled={!isOpenResult()}
              >
                Открыть в результатах
              </Button>
            </div>
          </div>
        </div>
      </Paper>
      {(graphLoading || loading) && (
        <Paper className={classes.noData}>
          <div className={classes.loadingSign}>{"< Пожалуйста подождите, идёт построение графа >"}</div>
        </Paper>
      )}
      {graphData.graphNodes.length !== 0 && (
        <Paper className={graphLoading ? classes.opacityZero : classes.opacityFull}>
          <div className={classes.nodesCount}>Кол-во вершин: {graphData.graphNodes.length}</div>
          <GraphView graphData={graphData} setNetwork={setNetwork} graphEvents={graphEvents} />
        </Paper>
      )}
      {graphData.graphNodes.length === 0 && !loading && !graphLoading && (
        <Paper className={classes.noData}>
          <NoData />
        </Paper>
      )}
    </div>
  );
};

export default Graph;
