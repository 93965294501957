import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { GridApi } from "ag-grid-community";
import { updateRow } from "components/agGrid/functions";
import AccessService from "services/admin/AccessService";
import { showErrorAlert } from "redux/actions/alertActions";
import { useDispatch } from "react-redux";
import { AccessDeleteRecord } from "types/admin";

// components
import AccessTable from "./components/AccessTable";
import UpdateAccessDialog from "./components/UpdateAccessDialog";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    margin: "0 5px",
  },
  search: {
    width: 400,
    "& input::placeholder": {
      fontSize: 14,
    },
  },
  searchInput: {
    fontSize: 14,
  },
}));

interface State {
  loading: boolean;
  rowData: AccessDeleteRecord[];
  error: undefined | Error;
}

const initialState: State = {
  loading: false,
  rowData: [],
  error: undefined,
};

const Access: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [state, setState] = useState<State>(initialState);
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<AccessDeleteRecord[]>([]);

  const { rowData, error } = state;

  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);

  const catchError = useCallback(
    (error: Error) => {
      dispatch(showErrorAlert(error.message));
    },
    [dispatch]
  );

  const onQuickFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  };

  const onSelection = () => {
    if (gridApi) {
      const rows = gridApi.getSelectedRows();
      setSelectedRows(rows);
    }
  };

  const handleCloseUpdateDialog = (data?: AccessDeleteRecord) => {
    setOpenUpdateDialog(false);
    if (data) {
      AccessService.setAccessRemoveRecords(data)
        .then(({ data }) => {
          updateRow(data, gridApi);
        })
        .catch((err) => catchError(err.response.data));
    }
  };

  useEffect(() => {
    if (error) {
      catchError(error);
    }
  }, [catchError, error]);

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    AccessService.getAccessRemoveRecords()
      .then((res) => {
        setState(() => ({ rowData: res.data ?? [], loading: false, error: undefined }));
      })
      .catch((err) => {
        setState(() => ({ rowData: [], loading: false, error: err }));
      });
  }, []);
  
  return (
    <Paper className={classes.root}>
      <div className={classes.actions}>
        <div className={classes.buttons}>
          <div>
            <TextField
              className={classes.search}
              placeholder="Имя"
              onChange={onQuickFilterChanged}
              InputProps={{
                className: classes.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => setOpenUpdateDialog(true)}
              disabled={selectedRows.length !== 1}
            >
              Редактировать
            </Button>
          </div>
        </div>
      </div>

      <AccessTable rowData={rowData} setGridApi={setGridApi} onSelection={onSelection} />

      {selectedRows.length === 1 && (
        <UpdateAccessDialog open={openUpdateDialog} onClose={handleCloseUpdateDialog} access={selectedRows[0]} />
      )}
    </Paper>
  );
};

export default Access;
