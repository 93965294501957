import React, { FC, useState, ChangeEvent } from "react";
import HintDialogWords from "./HintDialogWords";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles((theme) => ({
  textArea: {
    padding: theme.spacing(1),
    border: "none",
    outline: "none",
    width: "100%",
  },
  disableTransition: {
    transition: "none",
    position: "relative",
  },
}));

export interface State {
  text: string;
}

interface Props {
  anchorEl: any;

  id?: string;
  value: string;
  onSelection?: Function;
  filter: string;
}

const InputStringEditComponent: FC<Props> = ({ anchorEl, id, value, onSelection, filter }) => {
  const classes = useStyles();

  const [state, setState] = useState<State>({ text: value });

  const OnCloseCallback = () => {
    onSelection?.(state.text, "editAndClose");
  };

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    setState({ text: value });
  };

  const onKeyDown = (e: any) => {
    if (e.ctrlKey && e.key === "Enter") {
      onSelection?.(state.text, "edit");
    }

    if (e.key === "Escape") {
      onSelection?.(state.text, "editAndClose");
    }

    e.stopPropagation();
  };

  return (
    <div>
      <Popover
        className={classes.disableTransition}
        open
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => {
          OnCloseCallback();
        }}
      >
        <HintDialogWords />
        <textarea
          className={classes.textArea}
          rows={15}
          name="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={state.text}
          autoFocus
        />
      </Popover>
    </div>
  );
};

export default InputStringEditComponent;
