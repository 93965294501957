import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Record } from "types/record";
import RecordService from "services/RecordService";
import { useDispatch } from "react-redux";
import { showSuccessAlert } from "redux/actions/alertActions";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  buttons: {},
}));

interface Props {
  selectedRecord: Record;
}

interface State {
  loading: boolean;
  comment: string;
  error: Error | undefined;
}

const initialState: State = {
  loading: false,
  comment: "",
  error: undefined,
};

const TabComment: FC<Props> = ({ selectedRecord }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [state, setState] = useState(initialState);
  const { comment } = state;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setState((prev) => ({ ...prev, comment: value }));
  };

  const handleSave = () => {
    RecordService.setComment(selectedRecord.id, { comment }).then(() => {
      dispatch(showSuccessAlert("Комментарий сохранён."));
    });
  };

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    RecordService.getComment(selectedRecord.id)
      .then(({ data }) => {
        setState(() => ({ comment: data.comment, loading: false, error: undefined }));
      })
      .catch((error) => {
        setState(() => ({ comment: "", loading: false, error }));
      });
  }, [selectedRecord]);

  return (
    <div className={classes.root}>
      <TextField
        fullWidth
        className={classes.input}
        multiline
        rows={10}
        variant="outlined"
        value={comment}
        onChange={handleChange}
        onKeyDown={(e) => e.stopPropagation()}
      />
      <div className={classes.buttons}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Сохранить
        </Button>
      </div>
    </div>
  );
};

export default TabComment;
