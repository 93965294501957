import React, { FC, useEffect, useMemo } from "react";
import { API_URL, records } from "http/urls";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import { Keywords, RecordFileViewType, Segment } from "types";

// components
import AudioPlayer from "components/audioPlayer/AudioPlayer";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {},
}));

interface Props {
  queryFilter: string;
  viewType: RecordFileViewType.AUDIO;
  fileName: string;
  segments: Segment[];
  keywords: Keywords[];
  recordChannel: number;
  setAudioUrl: Function;
}

const WAVEFORM_HEIGHT = 150;

const Player: FC<Props> = ({ queryFilter, viewType, fileName, segments, keywords, recordChannel, setAudioUrl,  }) => {
  const classes = useStyles();

  const { accessToken } = useSelector((state: RootState) => state.auth);
  const {
    playerIsPan,
    playerIsAdeClick,
    playerIsNoise,
    playerIsKTCH,
    playerIsEcho,
    playerChannel,
    playerGain,
    playerWaveformType,
    resultPanelSize,
    isShowLayoutVad,
    playerZoom: zoomLevel,
    diarizationHighlight,
  } = useSelector((state: RootState) => state.settings);

  const waveformWidth = useMemo(() => Math.round((resultPanelSize - 15) * zoomLevel), [resultPanelSize, zoomLevel]);

  const audioSrc = useMemo(() => {
    const url = API_URL + records.getFileData;
    const isPan = playerIsPan === undefined ? 0 : playerIsPan;
    const isAdeClick = playerIsAdeClick === undefined ? 0 : playerIsAdeClick;
    const isKTCH = playerIsKTCH === undefined ? 0 : playerIsKTCH;
    const isNoise = playerIsNoise === undefined ? 0 : playerIsNoise;
    const isEcho = playerIsEcho === undefined ? 0 : playerIsEcho;
    const channel = playerChannel === undefined ? -1 : playerChannel;
    const gain = playerGain === undefined ? 1 : playerGain;
    const layoutVad = isShowLayoutVad === undefined ? 1 : isShowLayoutVad;
    const playerSettings = `&isPan=${isPan}&isAdeClick=${isAdeClick}&isNoise=${isNoise}&channel=${channel}&volume=${gain}&isKTCH=${isKTCH}&isEcho=${isEcho}&isShowLayoutVad=${layoutVad}`;
    return decodeURI(
      url.replace(":viewType", viewType) + `?q=${queryFilter}` + playerSettings + `&authorization=${accessToken}`
    );
  }, [
    queryFilter,
    viewType,
    accessToken,
    playerIsPan,
    playerIsAdeClick,
    playerIsKTCH,
    playerIsEcho,
    playerIsNoise,
    playerChannel,
    playerGain,
    isShowLayoutVad,
  ]);

  const waveformSrc = useMemo(() => {
    const url = API_URL + records.getFileData;
    const isPan = playerIsPan === undefined ? 0 : playerIsPan;
    const isAdeClick = playerIsAdeClick === undefined ? 0 : playerIsAdeClick;
    const isShowLayoutDiarization = diarizationHighlight ? 1 : 0;
    const isNoise = playerIsNoise === undefined ? 0 : playerIsNoise;
    const isKTCH = playerIsKTCH === undefined ? 0 : playerIsKTCH;
    const isEcho = playerIsEcho === undefined ? 0 : playerIsEcho;
    const channel = playerChannel === undefined ? -1 : playerChannel;
    const gain = playerGain === undefined ? 1 : playerGain;
    const layoutVad = isShowLayoutVad === undefined ? 1 : isShowLayoutVad;
    const playerSettings = `&isPan=${isPan}&isAdeClick=${isAdeClick}&isNoise=${isNoise}&channel=${channel}&volume=${gain}&isKTCH=${isKTCH}&isEcho=${isEcho}&isShowLayoutVad=${layoutVad}&isShowLayoutDiarization=${isShowLayoutDiarization}`;

    const waveformSettings = `&waveformType=${playerWaveformType}&w=${waveformWidth}&h=${WAVEFORM_HEIGHT}`;

    return decodeURI(
      url.replace(":viewType", "audio") +
        `?q=${queryFilter}&authorization=${accessToken}` +
        playerSettings +
        waveformSettings
    );
  }, [
    accessToken,
    queryFilter,
    playerIsPan,
    playerIsAdeClick,
    playerIsNoise,
    playerIsKTCH,
    playerIsEcho,
    playerChannel,
    playerGain,
    playerWaveformType,
    waveformWidth,
    isShowLayoutVad,
    diarizationHighlight,
  ]);

  useEffect(() => {
    setAudioUrl(audioSrc)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[audioSrc])

  return (
    <div className={classes.root}>
      <AudioPlayer
        audioSrc={audioSrc}
        waveformSrc={waveformSrc}
        fileName={fileName}
        segments={segments}
        keywords={keywords}
        waveformWidth={waveformWidth}
        recordChannel={recordChannel}
      />
    </div>
  );
};

export default Player;
