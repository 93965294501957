const normalize = (val: number) => (val < 10 ? `0${val}` : val);

export function dateIsValid(date: any) {
  // @ts-ignore
  return date instanceof Date && !isNaN(date);
}

export function getFormatDate(format: string) {
  const date = new Date(format);
  if (!dateIsValid(date)) {
    return "00-00-0000 00:00";
  }
  const year = date.getFullYear();
  const month = normalize(date.getMonth() + 1);
  const day = normalize(date.getDate());
  const hours = normalize(date.getHours());
  const minutes = normalize(date.getMinutes());
  // let seconds = normalize(date.getSeconds());

  // dd-mm-yyyy hh:mm
  return `${day}-${month}-${year} ${hours}:${minutes}`;
}
