import React, { FC } from "react";

//material
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import HintDialogWords from "./HintDialogWords";

const useStyles = makeStyles((theme) => ({
  hintWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  iconWrapper: {
    position: "relative",
    marginRight: "1rem",
  },
  minHeight24: {
    minHeight: "24px",
  },
}));

interface Props {
  option: string;
}

const HintRender: FC<Props> = ({ option }) => {
  const classes = useStyles();
  if (option === "Слова") {
    return (
      <div className={classes.hintWrapper}>
        <Typography noWrap className={classes.minHeight24}>
          {option}
        </Typography>
        <div className={classes.iconWrapper}>
          <HintDialogWords />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.hintWrapper}>
      <Typography noWrap className={classes.minHeight24}>
        {option}
      </Typography>
    </div>
  );
};

export default HintRender;
