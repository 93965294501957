import React from "react";
import { default as GraphVis } from "react-graph-vis";

import "./vis-network.css";

const GraphView = ({ graphData, setNetwork, graphEvents }) => {
  const graph = {
    nodes: graphData.graphNodes,
    edges: graphData.graphEdges,
  };

  let options = {
    autoResize: true,
    layout: {
      hierarchical: false,
      //randomSeed: 999,
      improvedLayout: false,
    },
    locale: "ru",
    interaction: {
      keyboard: false,
      multiselect: true,
    },

    //best physics
    physics: {
      barnesHut: {
        springConstant: 0,
        avoidOverlap: 0.2,
      },
      stabilization: {
        enabled: true, // This is here just to see what's going on from the very beginning.
      },
    },

    edges: {
      arrows: {
        to: false,
        from: false,
      },
    },
    // physics: {
    //   enabled: true,
    //   barnesHut: {
    //     gravitationalConstant: -50000, // This is the default * 25.
    //   },
    //   stabilization: {
    //     enabled: true, // This is here just to see what's going on from the very beginning.
    //   },
    // },
    height: "820px",
  };

  return <GraphVis graph={graph} options={options} events={graphEvents} getNetwork={setNetwork} />;
};

export default GraphView;
