import { useEffect, useState } from "react";
import { WordsCloudWord } from "types";
import RecordService from "services/RecordService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  words: WordsCloudWord[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  words: [],
  error: undefined,
};

const useWordCloud = (query: string) => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    setState({ error: undefined, words: [], loading: true });
    RecordService.getWordCloud(query)
      .then(({ data }) => {
        setState({ words: data, loading: false, error: undefined });
      })
      .catch((err) => {
        setState({ words: [], loading: false, error: err });
      });
  }, [query]);

  return state;
};

export default useWordCloud;
