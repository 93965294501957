import React, { FC, useRef, useState, useEffect } from "react";

// components
import InputStringEditComponent from "./InputStringEditComponent";

// icons
import CloseIcon from "@material-ui/icons/Close";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {},
  divText: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
    height: "21px",
    lineHeight: "21px",
    cursor: "text",
    marginRight: "2px",
  },
  spanText: {
    minWidth: "20px",
    maxWidth: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  StyleBtn: {
    color: grey[500],
    width: "16px",
    height: "16px",
  },
  StyleBtnSvg: {
    width: "16px",
    height: "16px",
  },
}));

export interface State {
  text: string;
}

interface Props {
  id?: string;
  value: string;
  color: string;
  isFocus: boolean;
  isVisibleDeleteButton?: boolean;

  onSelection?: Function;
  onOpen?: Function;
  onClickDelete?: Function;
  filter: string;
}

const InputStringComponent: FC<Props> = ({
  id,
  value,
  color,
  isFocus,
  isVisibleDeleteButton,

  onSelection,
  onOpen,
  onClickDelete,
  filter,
}) => {
  const classes = useStyles();
  const refDiv = useRef<any>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  useEffect(() => {
    if (isFocus && refDiv) {
      setAnchorEl(refDiv.current);
    } else {
      setAnchorEl(null);
    }
  }, [isFocus, refDiv]);

  return (
    <div>
      <div
        ref={refDiv}
        className={classes.divText}
        style={{
          background: color,
        }}
        onClick={(e) => onOpen?.()}
      >
        <span className={classes.spanText} title={value}>
          {value}
        </span>

        {isVisibleDeleteButton && (
          <IconButton
            className={classes.StyleBtn}
            disableRipple
            color="primary"
            title="Удалить фильтр"
            onClick={(e) => onClickDelete?.()}
          >
            <CloseIcon className={classes.StyleBtnSvg} />
          </IconButton>
        )}
      </div>

      {anchorEl && (
        <InputStringEditComponent anchorEl={anchorEl} id={id} value={value} onSelection={onSelection} filter={filter} />
      )}
    </div>
  );
};

export default InputStringComponent;
