import React, { FC } from "react";
import { SourceDetail as T, SourceType } from "types/source";

import SharedFolder from "pages/sources/components/sourceType/SharedFolder";
import SprutPostgres from "pages/sources/components/sourceType/SprutPostgres";
import SprutInterbase from "pages/sources/components/sourceType/SprutInterbase";
import SprutOracle from "pages/sources/components/sourceType/SprutOracle";
import VirtusPostgres from "pages/sources/components/sourceType/VirtusPostgres";
import Paragraf from "pages/sources/components/sourceType/Paragraf";
import Imemo from "pages/sources/components/sourceType/Imemo";
import Sip from "pages/sources/components/sourceType/Sip";
import NppNttSprutPostgres from "pages/sources/components/sourceType/NppNttSprutPostgres";
import Mag from "./sourceType/Mag";


interface Props {
  state: T;
  setState: (item: T) => void;
  disable: boolean;
}

const SourceDetail: FC<Props> = ({ state, setState, disable }) => {
  // Сетевая папка
  if (state.type === SourceType.SHARED_FOLDER) {
    return <SharedFolder state={state} setState={setState} disable={disable} />;
  }

  // Параграф
  if (state.type === SourceType.PARAGRAF) {
    return <Paragraf state={state} setState={setState} disable={disable} />;
  }

  // Sprut Postgres
  if (state.type === SourceType.SPRUT_POSTGRES) {
    return <SprutPostgres state={state} setState={setState} disable={disable} />;
  }

  // Imemo
  if (state.type === SourceType.IMEMO) {
    return <Imemo state={state} setState={setState} disable={disable} />;
  }

  // Sip
  if (state.type === SourceType.SIP) {
    return <Sip state={state} setState={setState} disable={disable} />;
  }

  // Sprut Interbase
  if (state.type === SourceType.SPRUT_INTERBASE) {
    return <SprutInterbase state={state} setState={setState} disable={disable} />;
  }

  // Sprut Oracle
  if (state.type === SourceType.SPRUT_ORACLE) {
    return <SprutOracle state={state} setState={setState} disable={disable} />;
  }

  // Virtus postgres
  if (state.type === SourceType.VIRTUS_POSTGRES) {
    return <VirtusPostgres state={state} setState={setState} disable={disable} />;
  }

  // NPP NTT sprut postgres
  if (state.type === SourceType.NPP_NTT_SPRUT_POSTGRES) {
    return <NppNttSprutPostgres state={state} setState={setState} disable={disable} />;
  }

  //MAG
  if (state.type === SourceType.MAG) {
    return <Mag state={state} setState={setState} disable={disable} />;
  }

  return null;
};

export default SourceDetail;
