import React, { ChangeEvent, FC } from "react";
import {
  SourceDetailMag,
} from "types/source";
// import useAvailableFilters from "hooks/admin/useAvailableFilters";

// components
import SleepSec from "../SleepSec";

// material ui
import Grid from "@material-ui/core/Grid";
import FolderMag from "../FolderMag";

interface Props {
  state: SourceDetailMag;
  setState: (item: SourceDetailMag) => void;
  disable: boolean;
}

const Mag: FC<Props> = ({ state, setState, disable }) => {
  // const { filters: availableFilters } = useAvailableFilters("records");
  const { folderDetail, sleepSec } = state;

  const onChangeNumber = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setState({ ...state, [name]: Number(value) });
  };

  // const handleChangeFolderDetailType = (event: ChangeEvent<{ value: unknown }>) => {
  //   const { value } = event.target;

  //   if (value === FolderType.SFTP) {
  //     setState({ ...state, folderDetail: defaultFolderMag });
  //   }
  // };

  // const handleChangeSmbVersion = (event: ChangeEvent<{ value: unknown }>) => {
  //   const { value } = event.target;
  // };

  const onChangeTextFolderDetail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = event.target;
    setState({ ...state, folderDetail: { ...state.folderDetail, [name]: value } });
  };

  // const setDeleteFilter = (f: Group) => {
  //   setState({ ...state, deleteFilter: JSON.stringify(f) });
  // };

  const setPathValue = (value: string, name?: string) => {
    const pathName = name === undefined ? "path" : name;
    setState({ ...state, folderDetail: { ...state.folderDetail, [pathName]: value } });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
      <FolderMag
            state={folderDetail}
            onChange={onChangeTextFolderDetail}
            setPathValue={setPathValue}
        />
      </Grid>

      {/* <Grid item xs={12}>
        <SourceFilter
          title="Исключить данные по фильтру"
          filter={deleteFilter}
          setFilter={setDeleteFilter}
          availableFilters={availableFilters}
        />
      </Grid> */}

      <Grid item xs={12}>
        <SleepSec sleepSec={sleepSec} setSleepSec={onChangeNumber} />
      </Grid>

      {/* <Grid item xs={12}>
        <TextField
          label="Виртуальная задержка чтения сеанса"
          fullWidth
          variant="standard"
          name="dateChangedSec"
          onChange={onChangeNumber}
          value={dateChangedSec}
        />
      </Grid> */}
    </Grid>
  );
};

export default Mag;