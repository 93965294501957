import React, { FC, useEffect, useMemo, useState } from "react";
import SplitterLayout from "react-splitter-layout";
import { RecordDetail, Record } from "types";
import { AdminTypeFilter } from "types/admin";
import { Group } from "types/queryBuilder";
import useQueryString from "hooks/useQueryString";
import useAvailableFilters from "hooks/admin/useAvailableFilters";
import AccessService from "services/admin/AccessService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";
import { setResultPanelSize } from "redux/actions/settingsActions";
import { setRemoveRecordsAccess, setShowAutoInformatorsName, setShowNameSpaceName, setShowSpeakersName } from "redux/actions/accessActions";
import { generateUuid } from "functions/common";
import { useHistory } from "react-router-dom";
import { routes } from "routes";

// components
import ResultDetail from "pages/results/resultDetail/ResultDetail";
import Search from "components/search/Search";
import Records from "./records/Records";

// material ui
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 100px)",
  },
  search: {
    paddingBottom: 5,
  },
  splitWrapper: {
    overflow: "hidden",
    position: "relative",
    height: "calc(100% - 35px)",
  },
}));

interface Props {
  filterString: string;
  availableFilters: AdminTypeFilter[];
}

const Results: FC<Props> = ({ filterString, availableFilters }) => {
  const defaultFilter: Group = useMemo(
    () => ({
      type: "group",
      uuid: generateUuid(),
      operation: "AND",
      filters: [],
    }),
    []
  );

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { resultPanelSize } = useSelector((state: RootState) => state.settings);
  const [size, setSize] = useState(0);
  const [filter, setFilter] = useState<Group>(defaultFilter);

  const [recordDetail, setRecordDetail] = useState<RecordDetail | undefined>(undefined);
  const [selectedRecord, setSelectedRecord] = useState<Record | undefined>(undefined);

  // запустит поиск по фильтру
  const handleSearch = (filter: Group) => {
    history.push(routes.results.path + "?f=" + JSON.stringify(filter));
  };

  const handleSecondaryPaneSizeChange = (value: number) => {
    setSize(value);
  };

  const handleDragEnd = () => {
    dispatch(setResultPanelSize(size));
  };

  useEffect(() => {
    try {
      const filter: Group = JSON.parse(filterString);
      setFilter(filter);
    } catch (e) {}
  }, [filterString]);

  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <Search
          filterString={filterString}
          handleSearch={handleSearch}
          availableFilters={availableFilters}
          filter={filter}
          setFilter={setFilter}
        />
      </div>

      <div className={classes.splitWrapper}>
        <SplitterLayout
          primaryIndex={0}
          primaryMinSize={200}
          secondaryMinSize={620}
          secondaryInitialSize={resultPanelSize}
          onSecondaryPaneSizeChange={handleSecondaryPaneSizeChange}
          onDragEnd={handleDragEnd}
        >
          <Records
            setFilter={setFilter}
            availableFilters={availableFilters}
            filterString={filterString}
            recordDetail={recordDetail}
            setRecordDetail={setRecordDetail}
            setSelectedRecord={setSelectedRecord}
          />
          <div>
            <ResultDetail recordDetail={recordDetail} selectedRecord={selectedRecord} filterString={filterString} />
          </div>
        </SplitterLayout>
      </div>
    </div>
  );
};

// получть query параметры
const GetQueryParams: FC<{ availableFilters: AdminTypeFilter[] }> = ({ availableFilters }) => {
  const [filterString, setFilterString] = useState<undefined | string>(undefined);
  const queryParams = useQueryString();

  // проверка на наличие query параметра f
  useEffect(() => {
    const filterString = queryParams.get("f");

    if (filterString !== null) {
      setFilterString(filterString);
    }
    if (filterString === null) {
      setFilterString("");
    }
  }, [queryParams]);

  if (filterString === undefined) {
    return null;
  }
  return <Results filterString={filterString} availableFilters={availableFilters} />;
};

const GetAvailableFilters: FC = () => {
  const dispatch = useDispatch();
  const { filters, loading } = useAvailableFilters("records");

  useEffect(() => {
    AccessService.getUserAccessRemoveRecords().then(({ data }) => {
      dispatch(setRemoveRecordsAccess(data.isCanDelete));
      dispatch(setShowNameSpaceName(data.isShowNameSpaceName));
      dispatch(setShowSpeakersName(data.isShowSpeakersName));
      dispatch(setShowAutoInformatorsName(data.isShowAutoInformatorsName));
    });
  }, [dispatch]);

  if (loading) {
    return null;
  }
  return <GetQueryParams availableFilters={filters} />;
};

export default GetAvailableFilters;
