import { useEffect, useState } from "react";
import { Word } from "types/word";
import WordsService from "services/WordService";
import { AxiosError } from "axios";

interface State {
  loading: boolean;
  words: Word[];
  error: undefined | AxiosError;
}

const initialState: State = {
  loading: false,
  words: [],
  error: undefined,
};

const useWords = () => {
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    WordsService.getAll()
      .then(({ data }) => {
        setState(() => ({ words: data, loading: false, error: undefined }));
      })
      .catch((err) => {
        setState(() => ({ words: [], loading: false, error: err }));
      });
  }, []);

  return state;
};

export default useWords;
