import React, { FC, useState } from "react";

// material ui
import { Theme, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles((theme: Theme) => ({
  contentDescription: {
    marginBottom: "1px",
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize + 2,
  },
  button: {
    width: 100,
  },
  example: {
    margin: "10px 0",
    background: grey[800],
    color: theme.palette.background.default,
    fontSize: theme.typography.fontSize + 2,
    padding: "0.5rem",
  },
  hintIcon: {
    color: grey[600],
    marginRight: "0.25rem",
    cursor: "pointer",
    position: "absolute",
    right: 0,
    zIndex: 1000,
  },
}));

const HintDialogWords: FC = () => {
  const classes = useStyles();
  const [hintOpen, setHintOpen] = useState(false);

  const handleOpenHint = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setHintOpen(true);
  };

  const handleCloseHint = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setHintOpen(false);
  };
  return (
    <div>
      <HelpOutlineIcon className={classes.hintIcon} onClick={handleOpenHint} />
      <Dialog open={hintOpen} onClose={handleCloseHint} maxWidth="lg" fullWidth>
        <DialogContent>
          <Typography variant="h6">Простой поиск слов</Typography>
          <div className={classes.contentDescription}>
            Слова, написанные с новой строчки, будут искаться независимо от положения в тексте и в любой форме.
          </div>
          <div className={classes.contentDescription}>
            Например, при вводе:
            <div className={classes.example}>
              передай <br />
              телефоны
            </div>
            будут показаны результаты, в которых встречается слово "передай" в любой форме (передала/передали) ИЛИ слово
            "телефоны" в любой форме (телефон/телефона).
          </div>
        </DialogContent>
        <DialogContent>
          <Typography variant="h6">Поиск слов подряд</Typography>
          <div className={classes.contentDescription}>Слова, написанные друг за другом, будут искаться подряд.</div>
          <div className={classes.contentDescription}>
            Например, при вводе: <div className={classes.example}>передай телефоны</div>
            будут показаны результаты, в которых встречается словосочетание "передай телефоны" в любой форме (передали
            телефон/передала телефоны).
          </div>
        </DialogContent>
        <DialogContent>
          <Typography variant="h6">Поиск по группам ключевых слов</Typography>
          <div className={classes.contentDescription}>
            Для поиска по группам ключевых слов необходимо написать символ @ и название группы.
          </div>
          <div className={classes.contentDescription}>
            Например, при вводе: <div className={classes.example}>@Имена</div>
            будут показаны результаты, в которых встречаются слова из группы "Имена" в любой форме.
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small" color="primary" onClick={handleCloseHint} className={classes.button}>
            Ок
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HintDialogWords;
