import React, { FC, useMemo } from "react";
import useWordCloud from "hooks/records/useWordCloud";
import { Record } from "types";

// components
import NoData from "components/NoData";
import WordsCloudMainWindow from "./WordsCloudMainWindow";

// material ui
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  selectedRecord: Record;
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    height: "calc(100vh - 183px)",
  },
  noData: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const TabWordsCloud: FC<Props> = ({ selectedRecord }) => {
  const classes = useStyles();

  const queryString = useMemo(() => {
    const filter = {
      type: "group",
      operation: "AND",
      filters: [
        {
          condition: "=",
          filter: "Идентификатор",
          type: "filter",
          value: String(selectedRecord.id),
        },
      ],
    };
    return "?filterv2=" + JSON.stringify(filter);
  }, [selectedRecord]);

  const { words, loading } = useWordCloud(queryString);
  const isShowWordsCloud = useMemo(() => !loading && words.length !== 0, [loading, words]);

  return (
    <div className={classes.root}>
      {isShowWordsCloud && <WordsCloudMainWindow words={words} />}
      {!isShowWordsCloud && (
        <div className={classes.noData}>
          <NoData />
        </div>
      )}
    </div>
  );
};

export default TabWordsCloud;
