import {
  SET_REMOVE_RECORDS_ACCESS,
  SET_SHOW_NAMESPACE_NAME,
  SET_SHOW_SPEAKERS_NAME,
  SET_SHOW_AUTOINFORMATORS_NAME,
  AccessAction,
} from "redux/types/accessTypes";

export function setRemoveRecordsAccess(payload: boolean): AccessAction {
  return {
    type: SET_REMOVE_RECORDS_ACCESS,
    payload,
  };
}

export function setShowNameSpaceName(payload: boolean): AccessAction {
  return {
    type: SET_SHOW_NAMESPACE_NAME,
    payload,
  };
}

export function setShowSpeakersName(payload: boolean): AccessAction {
  return {
    type: SET_SHOW_SPEAKERS_NAME,
    payload,
  };
}

export function setShowAutoInformatorsName(payload: boolean): AccessAction {
  return {
    type: SET_SHOW_AUTOINFORMATORS_NAME,
    payload,
  };
}