import {
  Popover,
  Select,
  MenuItem,
  Button,
  FormControl,
  Grid,
  InputLabel,
} from '@material-ui/core';
import clsx from "clsx";
import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { API_HOST } from "http/urls";
import { Segment, Word } from "types";

// material ui
import { createStyles, makeStyles } from "@material-ui/core/styles";
import DescriptionIcon from '@material-ui/icons/Description';
import { grey, orange } from '@material-ui/core/colors';
import ReplicaEditorService from 'services/ReplicaEditorService';

const useStyles  = makeStyles((theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    settings: {
      width: 400,
      padding: theme.spacing(2),
      margin: 0,
    },
    divider: {
      margin: theme.spacing(1, 0),
    },
    button: {},
    popover: {
      overflow: "hidden",
    },
    colorGrey: {
      color: grey[600],
    },
    diff: {
      width: 3,
      height: 15,
      backgroundColor: orange[600],
      marginRight: 10,
      marginLeft: -13,
    },
    item: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    container: {
      display: "flex",
      alignItems: "center",
    },
    hidden: {
      display: "none",
    },
    fc: {
    height: "fit-content",
    },
    mr10: {
    marginRight: 10,
    },
    w100: {
      width: '100%'
    },
    buttonWrapper: {
      width: '100%', 
      display: 'flex', 
      justifyContent: 'space-between', 
      gap: '1rem',
    }
}));


const displayOptions = [
  { value: '1 —', label: '1 — , 2 —' },
  { value: 'A —', label: 'A — , Б —' },
  { value: '1 корр. —', label: '1 корр. — , 2 корр. —' },
];

const OpenEditorButton = ({recordId, segments, audioUrl, }:{recordId: number, segments: Segment[], audioUrl: string, databaseText: string,  }) => {
  const classes = useStyles();
  const [speakerDisplayOption, setSpeakerDisplayOption] = useState('1 —');
  const align = 'left'
  // const [align, setAlign] = useState('left')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [displayType, setDisplayType] = useState(displayOptions[0].value);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [databaseText, setDatabaseText] = useState<string | null>(null);

  //склеиваю слова в предложения + ставлю префикс спикера (1корр. —)
  const transformed = segments.map((segment: Segment) => {
    const words = segment.words.map((word: Word) => {
      return `<span>${word.word}</span>`;
    });
        const wordsInSentence = words.join(' ');
        let speakerPrefix: string = '';
        if (speakerDisplayOption === '1 —') {
          speakerPrefix = `${segment.channelNumber + 1} —`;
        } else if (speakerDisplayOption === '1 корр. —') {
          speakerPrefix = `${segment.channelNumber + 1} корр. —`;
        } else {
          speakerPrefix = `${String.fromCharCode(65 + segment.channelNumber)} —`;
        }
      //добавить в зависимости от языка
    return `<p style="text-align: ${align}">${speakerPrefix} ${wordsInSentence}</p>`;
  }).join('\n');

    //TODO: вот здесь text: databaseText сделать.
    const openEditor = (type: string) => {
      // console.log(databaseText)
      //открыть в окне или во вкладке:
      const newWin = type === 'window' ? window.open(API_HOST + "/w", "_blank", "width=1400,height=1100") : window.open(API_HOST + "/w", );
      if (newWin) {
        let count = 0;
        const timer = setInterval(() => {
          // передаю: тип, сам текст, ссылка
          newWin.postMessage({ type: "Database", text: databaseText ? databaseText : transformed, audio: audioUrl, speakerDisplayOption: speakerDisplayOption }, "*");
          count++;
          if (count >= 1) {
            clearInterval(timer); 
          }
        }, 1000);
      }
    };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDisplayChange = (event: ChangeEvent<{ value: unknown }>) => {
    setDisplayType(event.target.value as string);
    setSpeakerDisplayOption(event.target.value as string)
  };

  const sendSTT = (event: any) => {
    //отправляю преобразованный текст из стт
      if (event.data.type === "STT") {
        if (event.source) {
          event.source.postMessage({ type: "STT", text: transformed }, "*");
        } 
      }
  }

  
  useEffect(() => {
  //если приходит в месседж с типом html, то это текст из редактора и нужно сохранить его в базу
    window.addEventListener("message", (event:any) => {
      if (event.data.type === 'HTML') {
        setDatabaseText(event.data.text)
      ReplicaEditorService.setText(recordId, {text: event.data.text})
      
      // console.log(event.data.text)
      }
    });
  }, [recordId,  ]);

  useEffect(() => {
    //если приходит в месседж с типом docx, то это текст из редактора и нужно сохранить его в базу
      window.addEventListener("message", (event:any) => {
        // можно тут сразу добавить, типа если тип docx или txt. То скачиваем, а потом подставляем этот тип в формат файла,
        // тогда будет скачиваться txt или docx. Сделать мб как то чтобы формировалось имя файла.  
        if (event.data.type === 'DOCX') {
          ReplicaEditorService.getFile({format: 'docx', text: event.data.text})
        }
        if (event.data.type === 'TXT') {
          ReplicaEditorService.getFile({format: 'txt', text: event.data.text})
        }
        // console.log(event.data.text)
      });
    }, []);

  useEffect(() => {
    //если приходит месседж с типом стт, значит в нужно отправить в редактор стт.
    if (segments) {
    window.addEventListener("message", (event:any) => {
      if (event.data.type === 'STT') {
      sendSTT(event)
      }
    });
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segments]);

  

  useEffect(() => {
    ReplicaEditorService.getAll(recordId)
      .then((res: any) => {
        setDatabaseText(res.data);
        // console.log('получил', res.data)
      })
      .catch();
  }, [recordId]);


  return (
    <>
    <Button  
      size='small' 
      color='primary' 
      variant="contained" 
      onClick={handleClick} 
      className={clsx(classes.mr10, classes.fc)} 
      title="Открыть в редакторе"
    >
      <DescriptionIcon />
    </Button>
    <Popover
      className={classes.popover}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Grid container spacing={2} className={classes.settings}>
        <Grid item xs={12}>
          <div className={classes.container}>
            <FormControl fullWidth className={classes.item}>
            <InputLabel id="select-speaker-label">Тип отображения корреспондентов:</InputLabel>
              <Select value={displayType} onChange={handleDisplayChange} fullWidth>
                {displayOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={clsx(classes.container, classes.w100)}>
              <div className={classes.buttonWrapper}>
                <Button variant="text" size='small' color='primary' onClick={() => openEditor('window')}>Открыть в окне</Button>
                <Button variant="text" size='small' color='primary' onClick={() => openEditor('tab')}>Открыть во вкладке</Button>
              </div>
          </div>
        </Grid>
        </Grid>
    </Popover>
  </>
  );
};

export default OpenEditorButton;
