import { AxiosResponse } from "axios";
import { Server, App } from "types/admin/server";
import { admin, synapse } from "http/urls";
import api from "http/api";

const { servers: route } = admin;

function ServerService() {
  function getAll(): Promise<AxiosResponse<Server[]>> {
    return api.get<Server[]>(route.getAll);
  }

  function addApp(app: App): Promise<AxiosResponse<Server[]>> {
    const url = route.addApp;
    return api.post(url, app);
  }

  function editApp(app: App): Promise<AxiosResponse<Server[]>> {
    const url = route.editApp;
    return api.put(url, app);
  }

  function remove(ids: string): Promise<AxiosResponse<Server[]>> {
    const url = route.remove.replace(":ids", ids);
    return api.delete(url);
  }

  function updateIp(data: { ids: number[]; newIp: string }): Promise<AxiosResponse<Server[]>> {
    const url = route.updateIp;
    return api.post(url, data);
  }

  function applyConfig(data: { ids: number[] }): Promise<AxiosResponse<Server[]>> {
    const url = route.applyConfig;
    return api.post(url, data);
  }

  function serverCommand(command: string): Promise<AxiosResponse<any>> {
    const url = synapse.command
    return api.post(url, {message: command});
  }

  return Object.freeze({
    getAll,
    addApp,
    editApp,
    remove,
    updateIp,
    applyConfig,
    serverCommand,
  });
}

export default ServerService();
